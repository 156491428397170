<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #6813aa;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M27 11H21V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H13C12.4696 3 11.9609 3.21071 11.5858 3.58579C11.2107 3.96086 11 4.46957 11 5V11H5C4.46957 11 3.96086 11.2107 3.58579 11.5858C3.21071 11.9609 3 12.4696 3 13V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H11V27C11 27.5304 11.2107 28.0391 11.5858 28.4142C11.9609 28.7893 12.4696 29 13 29H19C19.5304 29 20.0391 28.7893 20.4142 28.4142C20.7893 28.0391 21 27.5304 21 27V21H27C27.5304 21 28.0391 20.7893 28.4142 20.4142C28.7893 20.0391 29 19.5304 29 19V13C29 12.4696 28.7893 11.9609 28.4142 11.5858C28.0391 11.2107 27.5304 11 27 11ZM27 19H20C19.7348 19 19.4804 19.1054 19.2929 19.2929C19.1054 19.4804 19 19.7348 19 20V27H13V20C13 19.7348 12.8946 19.4804 12.7071 19.2929C12.5196 19.1054 12.2652 19 12 19H5V13H12C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V5H19V12C19 12.2652 19.1054 12.5196 19.2929 12.7071C19.4804 12.8946 19.7348 13 20 13H27V19Z"
      fill="#6813AA"
    />
  </svg>
  <h4 class="fs-5 mt-3">Plano de Saúde</h4>
</header>

<form class="px-3 border rounded">
  <div class="py-4">
    <label class="form-label fs-6">Você possui plano de saúde?</label>
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-1"
          name="healthInsurance"
          [(ngModel)]="dataState.insurance"
          (ngModelChange)="setOption('healthInsurance', 'isSelected', true)"
        />
        <label for="radio-1">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-2"
          name="healthInsurance"
          [(ngModel)]="dataState.insurance"
          (ngModelChange)="setOption('healthInsurance', 'isSelected', false)"
        />
        <label for="radio-2">Não</label>
      </div>
    </div>
  </div>

  <div class="border-top py-4" *ngIf="dataState.insurance">
    <label class="form-label fs-6">Qual o seu plano?</label>
    <input
      class="form-control mt-2 p-2"
      type="text"
      placeholder="Caso possua mais de um, informe todos aqui."
      name="insuranceName"
      [(ngModel)]="dataState.insuranceName"
      (ngModelChange)="setValuesForm()"
    />
    <div class="form-text">Máximo 500 caracteres</div>
  </div>

  <!-- <div *ngIf="dataState.insurance" class="py-4 border-top">
    <label class="form-label fs-6"
      >Teria interesse em receber gratuitamente um estudo personalizado visando
      reduzir o seu custo em plano de saúde?</label
    >
    <div class="">
      <input
        class="me-2"
        type="checkbox"
        id="interesse"
        name="interesse"
        [checked]="dataState.interest"
        [(ngModel)]="dataState.interest"
        (ngModelChange)="setValuesForm()"
      />
      <label class="" for="interesse">Sim, tenho interesse</label>
    </div>
  </div> -->
</form>
