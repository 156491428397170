<div class="container">
  <header
    class="border-start border-5 border-primary rounded px-3 py-2"
    style="box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1)"
  >
    <div class="py-2">
      <h3 class="fs-5 mb-2">Nos ajude a proteger outras famílias</h3>
      <p class="fs-6 m-0">
        Compartilhe o contato de pessoas que você conhece e entende que podem
        ser beneficiadas através de uma reunião como esta.
      </p>
    </div>
  </header>

  <div *ngFor="let item of dataLocal.listContacts; let i = index">
    <div>
      <app-contact
        [data]="item"
        (value)="updateContact($event, i)"
      ></app-contact>

      <button class="btn btn-outline-danger mt-3" (click)="deleteContact(i)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
          />
        </svg>
        EXCLUIR
      </button>
    </div>
    <hr style="margin-top: 2rem; margin-bottom: 2rem" />
  </div>

  <div class="row my-5 justify-content-center">
    <div class="col-auto">
      <button (click)="addContact()" class="m-auto btn p-2 btn-outline-primary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-plus-lg"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
          />
        </svg>
        Adicionar outro contato
      </button>
    </div>
  </div>
  <footer class="row justify-content-center">
    <div class="">
      <button
        class="btn btn-outline-primary"
        mat-button
        [routerLink]="'/success'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.0006 12C21.0006 12.1989 20.9216 12.3897 20.7809 12.5303C20.6403 12.671 20.4495 12.75 20.2506 12.75H5.5609L11.0312 18.2194C11.1009 18.2891 11.1562 18.3718 11.1939 18.4628C11.2316 18.5539 11.251 18.6515 11.251 18.75C11.251 18.8486 11.2316 18.9461 11.1939 19.0372C11.1562 19.1282 11.1009 19.2109 11.0312 19.2806C10.9615 19.3503 10.8788 19.4056 10.7878 19.4433C10.6967 19.481 10.5991 19.5004 10.5006 19.5004C10.402 19.5004 10.3045 19.481 10.2134 19.4433C10.1224 19.4056 10.0396 19.3503 9.96996 19.2806L3.21996 12.5306C3.15023 12.461 3.09491 12.3783 3.05717 12.2872C3.01943 12.1962 3 12.0986 3 12C3 11.9014 3.01943 11.8038 3.05717 11.7128C3.09491 11.6218 3.15023 11.539 3.21996 11.4694L9.96996 4.71938C10.1107 4.57865 10.3016 4.49959 10.5006 4.49959C10.6996 4.49959 10.8905 4.57865 11.0312 4.71938C11.1719 4.86011 11.251 5.05098 11.251 5.25C11.251 5.44903 11.1719 5.6399 11.0312 5.78063L5.5609 11.25H20.2506C20.4495 11.25 20.6403 11.329 20.7809 11.4697C20.9216 11.6103 21.0006 11.8011 21.0006 12Z"
            fill="#0A5DA1"
          />
        </svg>
        Voltar
      </button>
    </div>
  </footer>
</div>
