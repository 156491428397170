import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from './modules/home/home.component';
import { PresentationComponent } from './modules/presentation/presentation.component';
import { ObjectiveComponent } from './modules/objective/objective.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { SliderComponent } from './modules/home/components/slider/slider.component';
import { CarouselSliderComponent } from './modules/home/components/carousel-slider/carousel-slider.component';

import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FamilyFormComponent } from './modules/collect-data/components/family-form/family-form.component';
import { CollectDataComponent } from './modules/collect-data/collect-data.component';
import { JobFormComponent } from './modules/collect-data/components/job-form/job-form.component';
import { IncomeTaxComponent } from './modules/collect-data/components/income-tax/income-tax.component';
import { HealthInsuranceComponent } from './modules/collect-data/components/health-insurance/health-insurance.component';
import { InsuranceComponent } from './modules/collect-data/components/insurance/insurance.component';
import { PensionComponent } from './modules/collect-data/components/pension/pension.component';
import { PropertiesComponent } from './modules/collect-data/components/properties/properties.component';
import { VehiclesComponent as VehiclesFormComponent } from './modules/collect-data/components/vehicles/vehicles.component';

import { FormObjectivesComponent } from './modules/objective/form-objectives/form-objectives.component';
import { RetirementComponent } from './modules/objective/form-objectives/components/retirement/retirement.component';
import { TripsComponent } from './modules/objective/form-objectives/components/trips/trips.component';
import { VehiclesComponent } from './modules/objective/form-objectives/components/vehicles/vehicles.component';
import { MarriageComponent } from './modules/objective/form-objectives/components/marriage/marriage.component';
import { EntrepreneurshipComponent } from './modules/objective/form-objectives/components/entrepreneurship/entrepreneurship.component';
import { OwnPropertyComponent } from './modules/objective/form-objectives/components/own-property/own-property.component';
import { OthersComponent } from './modules/objective/form-objectives/components/others/others.component';
import { DatepickerComponent } from './modules/objective/form-objectives/components/datepicker/datepicker.component';
import { RemoveButtonComponent } from './modules/objective/form-objectives/components/remove-button/remove-button.component';
import { InvestmentsComponent } from './modules/collect-data/components/investments/investments.component';
import { ConsultingModelComponent } from './modules/collect-data/components/investments/component/consulting-model/consulting-model.component';
import { InssRetirementComponent } from './modules/collect-data/components/inss-retirement/inss-retirement.component';
import { DebtsComponent } from './modules/collect-data/components/debts/debts.component';
import { JobOfferComponent } from './modules/job-offer/job-offer.component';
import { MenuCollectComponent } from './modules/collect-data/components/menu-collect/menu-collect.component';
import { BudgetComponent } from './modules/collect-data/components/budget/budget.component';
import { PrefixedInputComponent } from './modules/collect-data/inputs-components/prefixed-input/prefixed-input.component';
import { CreditCardComponent } from './modules/collect-data/components/credit-card/credit-card.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { onboardingReduce } from './modules/home/store/home.reducer';
import { presentationReduce } from './modules/presentation/store/presentation.reducer';
import {
  objectiveEntrepreneurshipReduce,
  objectiveMarriageReduce,
  objectiveOthersReduce,
  objectivePropertyReduce,
  objectiveReduce,
  objectiveRetirementReduce,
  objectiveTripsReduce,
  objectiveVehiclesReduce,
} from './modules/objective/store/objective.reducer';

import { settingReduce } from './modules/settings/settings-general/store/setting.reducer';
import { EndOfCollectionComponent } from './modules/collect-data/components/end-of-collection/end-of-collection.component';
import { ContactComponent } from './modules/collect-data/components/end-of-collection/components/contact/contact.component';
import { AssetProjectionComponent } from './modules/collect-data/components/asset-projection/asset-projection.component';
import { collectDataReduce } from './modules/collect-data/store/collect-data.reducer';
import { EndComponent } from './modules/end/end.component';
import { jobOfferReduce } from './modules/job-offer/store/job-offer.reducer';
import { jobFormReduce } from './modules/collect-data/components/job-form/store/job-form.reducer';
import { familyFormReduce } from './modules/collect-data/components/family-form/store/family-form.reducer';
import { incomeTaxReduce } from './modules/collect-data/components/income-tax/store/incomeTax.reducer';
import { healthInsuranceReduce } from './modules/collect-data/components/health-insurance/store/healthInsurance.reducer';
import { insuranceReduce } from './modules/collect-data/components/insurance/store/insurance.reducer';
import { pensionReduce } from './modules/collect-data/components/pension/store/pension.reducer';
import { propertiesReduce } from './modules/collect-data/components/properties/store/properties.reducer';
import { vehiclesReduce } from './modules/collect-data/components/vehicles/store/vehicles.reducer';
import { investmentsReduce } from './modules/collect-data/components/investments/store/investments.reducer';
import { inssReduce } from './modules/collect-data/components/inss-retirement/store/inss.reducer';
import { budgetReduce } from './modules/collect-data/components/budget/store/budget.reducer';
import { creditCardReduce } from './modules/collect-data/components/credit-card/store/credit-card.reducer';
import { debtsReduce } from './modules/collect-data/components/debts/store/debts.reducer';
import { assetsReduce } from './modules/collect-data/components/asset-projection/store/assets.reducer';
import { ModalEndComponent } from './modules/end/components/modal-end/modal-end.component';
import { EndOfLifeComponent } from './modules/collect-data/components/end-of-life/end-of-life.component';
import { IframeSlideComponent } from './modules/presentation/iframe-slide/iframe-slide.component';
import { PdfReportComponent } from './modules/pdf-report/pdf-report.component';
import { HeaderComponent } from './modules/pdf-report/components/header/header.component';
import { FooterComponent } from './modules/pdf-report/components/footer/footer.component';
import { ObjectivesToPdfComponent } from './modules/pdf-report/components/objectives-to-pdf/objectives-to-pdf.component';
import { PdfRetirementComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-retirement/pdf-retirement.component';
import { PdfTripsComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-trips/pdf-trips.component';
import { PdfVehiclesComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-vehicles/pdf-vehicles.component';
import { PdfPropertyComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-property/pdf-property.component';
import { PdfOthersComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-others/pdf-others.component';
import { PdfMarriageComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-marriage/pdf-marriage.component';
import { PdfEntrepreneurshipComponent } from './modules/pdf-report/components/objectives-to-pdf/components/pdf-entrepreneurship/pdf-entrepreneurship.component';
import { CollectDataPdfComponent } from './modules/pdf-report/components/collect-data-pdf/collect-data-pdf.component';
import { PdfInformationComponent } from './modules/pdf-report/components/collect-data-pdf/components/pdf-information/pdf-information.component';
import { PdfProtectionComponent } from './modules/pdf-report/components/collect-data-pdf/components/pdf-protection/pdf-protection.component';
import { PdfPatrimonyComponent } from './modules/pdf-report/components/collect-data-pdf/components/pdf-patrimony/pdf-patrimony.component';
import { PdfBudgetComponent } from './modules/pdf-report/components/collect-data-pdf/components/pdf-budget/pdf-budget.component';
import { JobOfferPdfComponent } from './modules/pdf-report/components/job-offer-pdf/job-offer-pdf.component';
import { RecomendationPdfComponent } from './modules/pdf-report/components/recomendation-pdf/recomendation-pdf.component';
import { ContactReduce } from './modules/collect-data/components/end-of-collection/store/contact.reducer';
import { IframeSlideInvestimentsComponent } from './modules/collect-data/components/investments/component/iframe-slide-investiments/iframe-slide-investiments.component';
import { CoverPdfComponent } from './modules/pdf-report/components/cover-pdf/cover-pdf.component';
import { ConsultingReduce } from './modules/collect-data/components/investments/component/consulting-model/store/consulting.reducer';
import { FormsComponent } from './modules/collect-data/components/debts/component/forms/forms.component';
import { FormsVehicleComponent } from './modules/collect-data/components/vehicles/component/forms/forms.component';
import { FormsPropertiesComponent } from './modules/collect-data/components/properties/component/forms-properties/forms-properties.component';
import { IframeCapitalComponent } from './components/iframe-capital/iframe-capital.component';
import { DeliveriesComponent } from './modules/job-offer/components/deliveries/deliveries.component';
import { InvestimentsComponent } from './modules/job-offer/components/investiments/investiments.component';
import { IntelligenceComponent } from './modules/job-offer/components/deliveries/components/intelligence/intelligence.component';
import { AccessionComponent } from './modules/job-offer/components/deliveries/components/accession/accession.component';
import { ReportsComponent } from './modules/job-offer/components/deliveries/components/reports/reports.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { SettingsGeneralComponent } from './modules/settings/settings-general/settings-general.component';
import { SettingsJobOfferComponent } from './modules/settings/settings-job-offer/settings-job-offer.component';
import { SettingsPlansComponent } from './modules/settings/settings-plans/settings-plans.component';
import { SettingPlansReduce } from './modules/settings/settings-plans/store/setting-plans.reducer';
import { SettingJobReduce } from './modules/settings/settings-job-offer/store/setting-job.reducer';
import { FollowUpServiceComponent } from './modules/job-offer/components/investiments/components/follow-up-service/follow-up-service.component';
import { ConsultingProjectComponent } from './modules/job-offer/components/investiments/components/consulting-project/consulting-project.component';
import { AdvisorComponent } from './modules/job-offer/components/investiments/components/advisor/advisor.component';
import { FinancialConsultancyComponent } from './modules/job-offer/components/investiments/components/financial-consultancy/financial-consultancy.component';
import { CreditCardPaymentComponent } from './modules/settings/payment-method/components/credit-card-payment/credit-card-payment.component';
import { InCashPaymentComponent } from './modules/settings/payment-method/components/in-cash-payment/in-cash-payment.component';
import { OfferComponent } from './modules/settings/offer/offer.component';
import { settingsReduce } from './modules/settings/store/settings.reducer';
import { OfferReduce } from './modules/settings/offer/store/offer.reduce';
import { DefaultPricingComponent } from './modules/settings/info/default-pricing/default-pricing.component';
import { RbaPercentageComponent } from './modules/settings/info/rba-percentage/rba-percentage.component';
import { ConsultantProjectComponent } from './modules/settings/info/consultant-project/consultant-project.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastComponent } from './components/toast/toast.component';
import { InputToastComponent } from './components/input-toast/input-toast.component';
import { SavedToastComponent } from './components/saved-toast/saved-toast.component';
import { PreviewComponent } from './modules/settings/preview/preview.component';
import { HideDeliverablesComponent } from './modules/settings/offer/info/hide-deliverables/hide-deliverables.component';
import { FinancialConsultancyPreviewComponent } from './modules/settings/preview/financial-consultancy-preview/financial-consultancy-preview.component';
import { AdvisorPreviewComponent } from './modules/settings/preview/advisor-preview/advisor-preview.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PresentationComponent,
    ObjectiveComponent,
    FamilyFormComponent,
    CollectDataComponent,
    JobFormComponent,
    IncomeTaxComponent,
    HealthInsuranceComponent,
    InsuranceComponent,
    PensionComponent,
    PropertiesComponent,
    VehiclesComponent,
    SliderComponent,
    CarouselSliderComponent,
    TopbarComponent,
    SidebarComponent,
    FormObjectivesComponent,
    RetirementComponent,
    TripsComponent,
    MarriageComponent,
    EntrepreneurshipComponent,
    OwnPropertyComponent,
    OthersComponent,
    DatepickerComponent,
    RemoveButtonComponent,
    VehiclesFormComponent,
    InvestmentsComponent,
    ConsultingModelComponent,
    InssRetirementComponent,
    DebtsComponent,
    JobOfferComponent,
    MenuCollectComponent,
    BudgetComponent,
    PrefixedInputComponent,
    CreditCardComponent,
    EndOfCollectionComponent,
    ContactComponent,
    AssetProjectionComponent,
    EndComponent,
    ModalEndComponent,
    EndOfLifeComponent,
    IframeSlideComponent,
    PdfReportComponent,
    HeaderComponent,
    FooterComponent,
    ObjectivesToPdfComponent,
    PdfRetirementComponent,
    PdfTripsComponent,
    PdfVehiclesComponent,
    PdfPropertyComponent,
    PdfOthersComponent,
    PdfMarriageComponent,
    PdfEntrepreneurshipComponent,
    CollectDataPdfComponent,
    PdfInformationComponent,
    PdfProtectionComponent,
    PdfPatrimonyComponent,
    PdfBudgetComponent,
    JobOfferPdfComponent,
    RecomendationPdfComponent,
    IframeSlideInvestimentsComponent,
    CoverPdfComponent,
    FormsComponent,
    FormsVehicleComponent,
    FormsPropertiesComponent,
    IframeCapitalComponent,
    DeliveriesComponent,
    InvestimentsComponent,
    IntelligenceComponent,
    AccessionComponent,
    ReportsComponent,
    SettingsComponent,
    SettingsGeneralComponent,
    SettingsJobOfferComponent,
    SettingsPlansComponent,
    FollowUpServiceComponent,
    ConsultingProjectComponent,
    FinancialConsultancyComponent,
    AdvisorComponent,
    CreditCardPaymentComponent,
    InCashPaymentComponent,
    OfferComponent,
    DefaultPricingComponent,
    RbaPercentageComponent,
    ConsultantProjectComponent,
    ToastComponent,
    InputToastComponent,
    SavedToastComponent,
    PreviewComponent,
    HideDeliverablesComponent,
    FinancialConsultancyPreviewComponent,
    AdvisorPreviewComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatNativeDateModule,
    CommonModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatSnackBarModule,
    StoreModule.forRoot({
      onboardingReduce,
      presentationReduce,
      objectiveReduce,
      objectiveRetirementReduce,
      settingReduce,
      collectDataReduce,
      jobOfferReduce,
      jobFormReduce,
      familyFormReduce,
      incomeTaxReduce,
      healthInsuranceReduce,
      insuranceReduce,
      pensionReduce,
      propertiesReduce,
      vehiclesReduce,
      objectiveTripsReduce,
      objectivePropertyReduce,
      objectiveVehiclesReduce,
      objectiveEntrepreneurshipReduce,
      objectiveMarriageReduce,
      objectiveOthersReduce,
      investmentsReduce,
      inssReduce,
      budgetReduce,
      creditCardReduce,
      debtsReduce,
      assetsReduce,
      ContactReduce,
      ConsultingReduce,
      SettingPlansReduce,
      SettingJobReduce,
      OfferReduce,
      settingsReduce,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
