import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getBudgetSelectors } from 'src/app/modules/collect-data/components/budget/store/budget.selectors';
import { IBudgetState } from 'src/app/modules/collect-data/components/budget/store/budget.state';
import { setSettingsAction } from 'src/app/modules/settings/store/settings.actions';
import { getSettingsSelectors } from 'src/app/modules/settings/store/settings.selectors';
import { ISettingsState } from 'src/app/modules/settings/store/settings.state';

@Component({
  selector: 'app-advisor',
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.scss'],
})
export class AdvisorComponent implements OnInit {
  dataBudgetState$: Observable<IBudgetState>;
  dataBudgetState: IBudgetState;

  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  isCreditCardSelected: boolean = false;
  isInCashSelected: boolean = false;

  financialConsultantValueBr: string = '';
  followUpServiceValueBr: string = '';
  advisorValueBr: string = '';
  advisorValueWithDiscountBr: string = '';

  advisorInstallmentValueBr: string = '';

  constructor(private store: Store) {}
  ngOnInit(): void {
    this.getBudgetState();
    this.getState();
    this.processingData();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };

      if (this.dataStoreSetting.jobOfferCardSelect == 'advisor credit card') {
        this.isCreditCardSelected = true;
        this.isInCashSelected = false;
      } else if (
        this.dataStoreSetting.jobOfferCardSelect == 'advisor in cash'
      ) {
        this.isCreditCardSelected = false;
        this.isInCashSelected = true;
      } else {
        this.isCreditCardSelected = false;
        this.isInCashSelected = false;
      }
    });
  }

  getBudgetState() {
    this.dataBudgetState$ = this.store.select(getBudgetSelectors);
    this.dataBudgetState$.subscribe((value) => {
      this.dataBudgetState = { ...value };
    });
  }

  setState(data: ISettingsState): void {
    this.store.dispatch(setSettingsAction(data));
  }

  async processingData() {
    await this.validateFinancialConsultantValue();
    this.financialConsultantValueBr = this.formatToBRL(
      this.dataStoreSetting.financialConsultantValue
    );

    this.followUpServiceValueBr = this.formatToBRL(
      this.dataStoreSetting.followUpServiceValue
    );

    this.advisorValueBr = this.formatToBRL(this.dataStoreSetting.advisorValue);

    this.advisorValueWithDiscountBr = this.formatToBRL(
      this.dataStoreSetting.advisorValueWithDiscount
    );

    this.advisorInstallmentValueBr = this.formatToBRL(
      this.dataStoreSetting.installmentAdvisorValue
    );
  }

  async validateFinancialConsultantValue() {
    let annualRecipeValue = this.dataBudgetState.annualRecipe;
    let totalValue =
      annualRecipeValue * (this.dataStoreSetting.percentValue / 100);

    if (totalValue > this.dataStoreSetting.manualValue) {
      this.dataStoreSetting.financialConsultantValue = totalValue;
    } else {
      this.dataStoreSetting.financialConsultantValue =
        this.dataStoreSetting.manualValue;
    }

    await this.validadeAdvisorValue();
    await this.updateCreditCardValues();
    await this.updateInCashValues();
  }

  validadeAdvisorValue(): void {
    this.dataStoreSetting.advisorValue =
      this.dataStoreSetting.financialConsultantValue +
      this.dataStoreSetting.followUpServiceValue;
  }

  updateCreditCardValues(): void {
    this.dataStoreSetting.installmentFinancialConsultantValue =
      this.dataStoreSetting.financialConsultantValue /
      this.dataStoreSetting.installments;
    this.dataStoreSetting.installmentAdvisorValue =
      this.dataStoreSetting.advisorValue / this.dataStoreSetting.installments;
  }

  updateInCashValues(): void {
    this.dataStoreSetting.financialConsultantValueWithDiscount =
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.financialConsultantValue
      );
    this.dataStoreSetting.advisorValueWithDiscount =
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.advisorValue
      );
  }

  applyingDiscountPercentage(percentage: number, value: number): number {
    if (percentage < 0 || percentage > 100) {
      return value;
    }
    if (value < 0) {
      return value;
    }
    const discount = (percentage / 100) * value;
    const discountedValue = value - discount;
    return discountedValue;
  }

  creditCardSelected(event: any) {
    this.isInCashSelected = false;
    if (this.isCreditCardSelected) {
      this.dataStoreSetting.jobOfferCardSelect = 'advisor credit card';
      this.setState(this.dataStoreSetting);
    } else {
      this.dataStoreSetting.jobOfferCardSelect = '';
      this.setState(this.dataStoreSetting);
    }
  }

  inCashSelected(event: any) {
    this.isCreditCardSelected = false;
    if (this.isInCashSelected) {
      this.dataStoreSetting.jobOfferCardSelect = 'advisor in cash';
      this.setState(this.dataStoreSetting);
    } else {
      this.dataStoreSetting.jobOfferCardSelect = '';
      this.setState(this.dataStoreSetting);
    }
  }

  formatToBRL(value: number) {
    let response = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value));

    return response;
  }
}
