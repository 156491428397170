<div
  style="min-width: 320px; max-width: 70vw"
  class="container objective-forms"
>
  <div class="row px-3">
    <div class="col-12">
      <h3 class="fs-5 fw-bold">Objetivos</h3>
      <p>
        Agora, preencha os campos de informações sobre cada objetivo
        selecionado.
      </p>
    </div>
    <div
      class="overflow-x-hidden overflow-y-auto pe-3 pt-2 pb-4"
      style="height: 72vh"
    >
      <div class="row gy-4">
        <app-retirement *ngIf="dataRetirementState.isSelected"></app-retirement>
        <app-trips *ngIf="dataTripsState.isSelected"></app-trips>
        <app-own-property
          *ngIf="dataPropertyState.isSelected"
        ></app-own-property>
        <app-vehicles *ngIf="dataVehiclesState.isSelected"></app-vehicles>
        <app-entrepreneurship
          *ngIf="dataEntrepreneurshipState.isSelected"
        ></app-entrepreneurship>
        <app-marriage *ngIf="dataMarriageState.isSelected"></app-marriage>
        <app-others *ngIf="dataOthersState.isSelected"></app-others>
      </div>
    </div>
  </div>
  <div
    class="footer w-100 position-fixed bottom-0 border-top mb-2"
    style="max-width: 70vw"
  >
    <div class="m-2">
      <footer class="pt-3">
        <div class="d-flex justify-content-between">
          <button
            mat-button
            id="backToPresentation"
            class="btn btn-outline-secondary back-button px-2 order-1 order-md-0"
            routerLink="/objective"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.0001 12C21.0001 12.1989 20.9211 12.3897 20.7804 12.5303C20.6398 12.671 20.449 12.75 20.2501 12.75H5.56041L11.0307 18.2194C11.1004 18.289 11.1557 18.3718 11.1934 18.4628C11.2311 18.5539 11.2505 18.6514 11.2505 18.75C11.2505 18.8485 11.2311 18.9461 11.1934 19.0372C11.1557 19.1282 11.1004 19.2109 11.0307 19.2806C10.961 19.3503 10.8783 19.4056 10.7873 19.4433C10.6962 19.481 10.5986 19.5004 10.5001 19.5004C10.4016 19.5004 10.304 19.481 10.2129 19.4433C10.1219 19.4056 10.0392 19.3503 9.96948 19.2806L3.21948 12.5306C3.14974 12.461 3.09443 12.3782 3.05668 12.2872C3.01894 12.1961 2.99951 12.0986 2.99951 12C2.99951 11.9014 3.01894 11.8038 3.05668 11.7128C3.09443 11.6217 3.14974 11.539 3.21948 11.4694L9.96948 4.71936C10.1102 4.57863 10.3011 4.49957 10.5001 4.49957C10.6991 4.49957 10.89 4.57863 11.0307 4.71936C11.1715 4.8601 11.2505 5.05097 11.2505 5.24999C11.2505 5.44901 11.1715 5.63988 11.0307 5.78061L5.56041 11.25H20.2501C20.449 11.25 20.6398 11.329 20.7804 11.4697C20.9211 11.6103 21.0001 11.8011 21.0001 12Z"
                fill="#0A5DA1"
              />
            </svg>
            Voltar
          </button>
          <button
            id="continuedToCollect"
            mat-raised-button
            class="btn btn-primary px-2 mx-2 order-0 order-md-1"
            routerLink="/collect-data"
            (click)="checkObjective()"
          >
            Continuar para coleta de dados
            <svg
              class="ms-2"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15.75 14.25C15.75 14.4489 15.671 14.6397 15.5303 14.7803C15.3897 14.921 15.1989 15 15 15H9C8.80109 15 8.61032 14.921 8.46967 14.7803C8.32902 14.6397 8.25 14.4489 8.25 14.25C8.25 14.0511 8.32902 13.8603 8.46967 13.7197C8.61032 13.579 8.80109 13.5 9 13.5H15C15.1989 13.5 15.3897 13.579 15.5303 13.7197C15.671 13.8603 15.75 14.0511 15.75 14.25ZM15 10.5H9C8.80109 10.5 8.61032 10.579 8.46967 10.7197C8.32902 10.8603 8.25 11.0511 8.25 11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12H15C15.1989 12 15.3897 11.921 15.5303 11.7803C15.671 11.6397 15.75 11.4489 15.75 11.25C15.75 11.0511 15.671 10.8603 15.5303 10.7197C15.3897 10.579 15.1989 10.5 15 10.5ZM20.25 4.5V20.25C20.25 20.6478 20.092 21.0294 19.8107 21.3107C19.5294 21.592 19.1478 21.75 18.75 21.75H5.25C4.85218 21.75 4.47064 21.592 4.18934 21.3107C3.90804 21.0294 3.75 20.6478 3.75 20.25V4.5C3.75 4.10218 3.90804 3.72065 4.18934 3.43934C4.47064 3.15804 4.85218 3 5.25 3H8.64937C9.07079 2.52818 9.58709 2.15068 10.1645 1.89221C10.7419 1.63374 11.3674 1.50014 12 1.50014C12.6326 1.50014 13.2581 1.63374 13.8355 1.89221C14.4129 2.15068 14.9292 2.52818 15.3506 3H18.75C19.1478 3 19.5294 3.15804 19.8107 3.43934C20.092 3.72065 20.25 4.10218 20.25 4.5ZM9 6H15C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6ZM18.75 4.5H16.2422C16.4128 4.9817 16.5 5.48898 16.5 6V6.75C16.5 6.94891 16.421 7.13968 16.2803 7.28033C16.1397 7.42098 15.9489 7.5 15.75 7.5H8.25C8.05109 7.5 7.86032 7.42098 7.71967 7.28033C7.57902 7.13968 7.5 6.94891 7.5 6.75V6C7.50002 5.48898 7.58721 4.9817 7.75781 4.5H5.25V20.25H18.75V4.5Z"
                fill="#F9F9FB"
              />
            </svg>
          </button>
        </div>
      </footer>
    </div>
  </div>
</div>
