<div style="min-width: 320px; max-width: 70vw" class="container presentation">
  <div class="pe-4" style="height: 75vh">
    <div class="presentation-header">
      <h3 class="fs-5 fw-bold">Apresentação</h3>
      <p>O modelo mais bem-sucedido do mundo, agora no Brasil.</p>
    </div>
    <div class="row bg-black rounded my-4 mx-auto">
      <div class="w-50 mx-auto py-3">
        <div
          class=""
          [ngStyle]="{ display: iframeVisivel ? 'none' : 'flex' }"
          (click)="openDialog()"
        >
          <img
            src="/assets/img/thumbnail_presentation.png"
            class="w-100 blur"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="footer w-100 position-fixed bottom-0 border-top mb-2"
    style="max-width: 70vw"
  >
    <div class="d-flex justify-content-end m-2">
      <footer class="pt-4">
        <a
          id="continuedPresentation"
          class="btn btn-primary btn-md align-items-center p-2"
          routerLink="/objective"
          (click)="validateStatePage()"
          >Continuar
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.7806 12.5306L14.0306 19.2806C13.8899 19.4214 13.699 19.5004 13.5 19.5004C13.301 19.5004 13.1101 19.4214 12.9694 19.2806C12.8286 19.1399 12.7496 18.949 12.7496 18.75C12.7496 18.551 12.8286 18.3601 12.9694 18.2194L18.4397 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H18.4397L12.9694 5.78063C12.8286 5.6399 12.7496 5.44902 12.7496 5.25C12.7496 5.05098 12.8286 4.86011 12.9694 4.71938C13.1101 4.57865 13.301 4.49958 13.5 4.49958C13.699 4.49958 13.8899 4.57865 14.0306 4.71938L20.7806 11.4694C20.8504 11.539 20.9057 11.6217 20.9434 11.7128C20.9812 11.8038 21.0006 11.9014 21.0006 12C21.0006 12.0986 20.9812 12.1962 20.9434 12.2872C20.9057 12.3783 20.8504 12.461 20.7806 12.5306Z"
              fill="#F9F9FB"
            />
          </svg>
        </a>
      </footer>
    </div>
  </div>
</div>
