import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ISettingsState } from '../store/settings.state';
import {
  MatSnackBar,
} from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { setSettingsAction } from '../store/settings.actions';
import { getSettingsSelectors } from '../store/settings.selectors';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  _simulatedValueString: string;

  constructor(private store: Store, private _snackBar: MatSnackBar) {}
  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = { ...value };
      this.validateValueSimulated();
      this.updateValues();
    });
    this.setState(this.dataStoreSetting);
  }
  setState(data: ISettingsState) {
    this.store.dispatch(setSettingsAction(data));
  }

  get simulatedValueString(): string {
    return this._simulatedValueString;
  }

  set simulatedValueString(value: string) {
    this._simulatedValueString = this.applyNumberMask(value);
    this.dataStoreSetting.simulatedValue = this.convertCurrencyToInteger(
      this.simulatedValueString
    );

    this.setState(this.dataStoreSetting);
  }

  updateValues(): void {
    this._simulatedValueString = this.applyNumberMask(
      this.dataStoreSetting.simulatedValue.toString()
    );
  }

  applyNumberMask(value: string): string {
    value = value.replace(/\D/g, '');
    const parts = value
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g);
    const formattedValue = parts
      ? parts.join('.').split('').reverse().join('')
      : value;
    return `R$ ${formattedValue}`;
  }

  convertCurrencyToInteger(numberString: string): number {
    const cleanedString = numberString.replace(/\D/g, '');
    return parseInt(cleanedString, 10);
  }

  validateValueSimulated(): void {
    this.dataStoreSetting.financialConsultantSimulatedValue =
      this.dataStoreSetting.simulatedValue *
      (this.dataStoreSetting.percentValue / 100);
  }

  convertValue(value: number): string {
    let response = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value));

    return response;
  }
}
