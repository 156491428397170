import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getObjectiveSelectors } from 'src/app/modules/objective/store/objective.selectors';
import { IObjectiveState } from 'src/app/modules/objective/store/objective.state';
import { IOnboardingState } from 'src/app/modules/home/store/home.state';
import { getPresentationSelectors } from 'src/app/modules/presentation/store/presentation.selectors';
import { IPresentationState } from 'src/app/modules/presentation/store/presentation.state';
import { ICollectDataState } from 'src/app/modules/collect-data/store/collect-data.state';
import { getCollectDataSelectors } from 'src/app/modules/collect-data/store/collect-data.selectors';
import { Location } from '@angular/common';
import { IJobOfferState } from 'src/app/modules/job-offer/store/job-offer.state';
import { getJobOfferSelectors } from 'src/app/modules/job-offer/store/job-offer.selectors';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(private store: Store, private location: Location) {
    this.location.onUrlChange(
      (url) => (this.currentUrl = url) && (this.selectedSection = url)
    );
  }
  currentUrl: string = '/';

  show: boolean = true;

  selectedSection: string;
  check$: Observable<any>;
  dataOnboardingState: IOnboardingState;
  dataObjectiveState: IObjectiveState;
  dataPresetationState: IPresentationState;
  dataCollectDataState: ICollectDataState;
  dataJobOfferState: IJobOfferState;

  updateSection(section: string) {
    this.selectedSection = section;
  }

  public updateState() {
    this.show = !this.show;
  }

  async ngOnInit() {
    await this.getStateOnboarding();
    await this.getStateObjective();
    await this.getStateApresentation();
    await this.getStateCollectData();
    await this.getStateJobOffer();
    this.location.onUrlChange((url) => (this.selectedSection = url));
    await this.updateSection(this.selectedSection);
  }

  getStateOnboarding() {
    this.check$ = this.store.select(getPresentationSelectors);

    this.check$.subscribe((value) => {
      this.dataOnboardingState = value;
    });
  }

  getStateObjective() {
    this.check$ = this.store.select(getObjectiveSelectors);

    this.check$.subscribe((value) => {
      this.dataObjectiveState = value;
    });
  }

  getStateApresentation() {
    this.check$ = this.store.select(getPresentationSelectors);

    this.check$.subscribe((value) => {
      this.dataPresetationState = value;
    });
  }

  getStateCollectData() {
    this.check$ = this.store.select(getCollectDataSelectors);

    this.check$.subscribe((value) => {
      this.dataCollectDataState = value;
    });
  }

  getStateJobOffer() {
    this.check$ = this.store.select(getJobOfferSelectors);

    this.check$.subscribe((value) => {
      this.dataJobOfferState = value;
    });
  }
}
