<div class="container-fluid p-2">
  <h1
    class="fs-2 fw-bold text-center justify-content-center font-weight-bold mb-4"
  >
    Bem-vindo(a) ao Guia<sup>®</sup>
  </h1>
  <div class="d-flex justify-content-center">
    <div style="max-width: 1100px" class="row">
      <img
        src="../../../assets/img/image-onboarding.svg"
        class="mb-3 mx-auto img-fluid"
      />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="d-flex justify-content-center">
      <a
        id="initialGuia"
        class="btn btn-primary d-flex p-2 align-itens-center"
        (click)="startTrial()"
        routerLink="/presentation"
      >
        INICIAR GUIA
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-check-lg ms-2"
          viewBox="0 0 16 16"
        >
          <path
            d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022"
          />
        </svg>
      </a>
    </div>
  </div>
</div>
