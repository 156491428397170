import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ISettingsState } from '../../store/settings.state';
import { Store } from '@ngrx/store';
import { getSettingsSelectors } from '../../store/settings.selectors';
import { setSettingsAction } from '../../store/settings.actions';

@Component({
  selector: 'app-financial-consultancy-preview',
  templateUrl: './financial-consultancy-preview.component.html',
  styleUrls: ['./financial-consultancy-preview.component.scss'],
})
export class FinancialConsultancyPreviewComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  isCreditCardSelected: boolean = false;
  isInCashSelected: boolean = false;

  financialConsultantValueBr: string = '';
  financialConsultantValueWithDiscountBr: string = '';

  financialConsultantInstallmentValueBr: string = '';

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
    this.processingData();
  }

  async processingData() {
    await this.validateFinancialConsultantValue();

    this.financialConsultantValueBr = this.formatToBRL(
      this.dataStoreSetting.financialConsultantValue
    );

    this.financialConsultantValueWithDiscountBr = this.formatToBRL(
      this.dataStoreSetting.financialConsultantValueWithDiscount
    );

    this.financialConsultantInstallmentValueBr = this.formatToBRL(
      this.dataStoreSetting.installmentFinancialConsultantValue
    );
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };

      if (
        this.dataStoreSetting.jobOfferCardSelect ==
        'financial consultant credit card'
      ) {
        this.isCreditCardSelected = true;
        this.isInCashSelected = false;
      } else if (
        this.dataStoreSetting.jobOfferCardSelect ==
        'financial consultant in cash'
      ) {
        this.isCreditCardSelected = false;
        this.isInCashSelected = true;
      } else {
        this.isCreditCardSelected = false;
        this.isInCashSelected = false;
      }
      this.processingData();
    });
  }

  setState(data: ISettingsState): void {
    this.store.dispatch(setSettingsAction(data));
  }

  validateFinancialConsultantValue(): void {
    let annualRecipeValue = this.dataStoreSetting.simulatedValue;
    let totalValue =
      annualRecipeValue * (this.dataStoreSetting.percentValue / 100);

    if (totalValue > this.dataStoreSetting.manualValue) {
      this.dataStoreSetting.financialConsultantValue = totalValue;
    } else {
      this.dataStoreSetting.financialConsultantValue =
        this.dataStoreSetting.manualValue;
    }

    this.updateCreditCardValues();
    this.updateInCashValues();
  }

  updateCreditCardValues(): void {
    this.dataStoreSetting.installmentFinancialConsultantValue =
      this.dataStoreSetting.financialConsultantValue /
      this.dataStoreSetting.installments;
    this.dataStoreSetting.installmentAdvisorValue =
      this.dataStoreSetting.advisorValue / this.dataStoreSetting.installments;
  }

  updateInCashValues(): void {
    this.dataStoreSetting.financialConsultantValueWithDiscount =
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.financialConsultantValue
      );
    this.dataStoreSetting.advisorValueWithDiscount =
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.advisorValue
      );
  }

  applyingDiscountPercentage(percentage: number, value: number): number {
    if (percentage < 0 || percentage > 100) {
      return value;
    }
    if (value < 0) {
      return value;
    }
    const discount = (percentage / 100) * value;
    const discountedValue = value - discount;
    return discountedValue;
  }

  creditCardSelected(event: any) {
    this.isInCashSelected = false;
    if (this.isCreditCardSelected) {
      this.dataStoreSetting.jobOfferCardSelect =
        'financial consultant credit card';
      this.setState(this.dataStoreSetting);
    } else {
      this.dataStoreSetting.jobOfferCardSelect = '';
      this.setState(this.dataStoreSetting);
    }
  }

  inCashSelected(event: any) {
    this.isCreditCardSelected = false;
    if (this.isInCashSelected) {
      this.dataStoreSetting.jobOfferCardSelect = 'financial consultant in cash';
      this.setState(this.dataStoreSetting);
    } else {
      this.dataStoreSetting.jobOfferCardSelect = '';
      this.setState(this.dataStoreSetting);
    }
  }

  formatToBRL(value: number): string {
    if (isNaN(value)) {
      value = 0;
    }

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
